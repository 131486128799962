import React, { useState, useEffect } from 'react';
import {
  Admin,
  Resource,
} from 'react-admin';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import moment from 'moment-timezone';
import authProvider from './providers/authProvider';
import dataProviderFactory from './providers/providerFactory';
import Layout from './layout/Layout/Layout';
import Login from './layout/Login/Login';
import customRoutes from './routes';
import users from './resources/users';
import promotions from './resources/promotions';
import retailers from './resources/retailers';
import orders from './resources/orders';
import products from './resources/products';
import themeMaterial from './themes/themeMaterial';
import messages from './i18n';
import comments from './resources/comments';
import blackList from './resources/blackList';
import staticContent from './resources/static';
import cards from './resources/cards';
import manufacturers from './resources/manufacturers';

moment.tz.setDefault('Europe/Paris');

const i18nProvider = polyglotI18nProvider(
  locale => (messages[locale] ? messages[locale] : messages.en),
  'en',
  { allowMissing: true },
);


const useStyles = makeStyles(theme => ({
  loaderContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    textAling: 'center',
    '& > * + *': {
      marginLeft: theme.spacing(),
    },
  },
}));

const App = () => {
  const [dataProvider, setDataProvider] = useState(null);
  const classes = useStyles();
  useEffect(() => {
    const fetchDataProvider = async () => {
      const dataProviderInstance = await dataProviderFactory(
        'graphql',
      );
      setDataProvider(
        () => dataProviderInstance,
      );
    };
    fetchDataProvider();
  }, []);
  if (!dataProvider) {
    return (
      <div className={classes.loaderContainer}>
        <CircularProgress />
        <div className="loader">Loading...</div>
      </div>
    );
  }
  return (
    <Admin
      authProvider={authProvider}
      dataProvider={dataProvider}
      title="SAZ Admin"
      layout={Layout}
      loginPage={Login}
      theme={themeMaterial}
      i18nProvider={i18nProvider}
      customRoutes={customRoutes}
      disableTelemetry
    >
      {permissions => [
        permissions ? <Resource name="users" {...users} /> : null,
        permissions ? <Resource name="cards" options={{ label: 'Loyalty Cards' }} {...cards} /> : null,
        permissions ? <Resource name="promotions" {...promotions} /> : null,
        permissions ? <Resource name="retailers" options={{ label: 'Exchange Points' }} {...retailers} /> : null,
        permissions ? <Resource name="orders" {...orders} /> : null,
        permissions ? <Resource name="products" {...products} /> : null,
        permissions ? <Resource name="manufacturers" {...manufacturers} /> : null,
        permissions ? <Resource name="comments" {...comments} /> : null,
        permissions ? <Resource name="blacklist" {...blackList} /> : null,
        permissions ? <Resource name="unSubs" /> : null,
        permissions ? <Resource name="static" options={{ label: 'Static Content' }} {...staticContent} /> : null,
      ]}
    </Admin>
  );
};

export default App;
