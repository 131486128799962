import React, { useState, useEffect } from 'react';
import {
  useTranslate,
  Loading,
  useDataProvider,
} from 'react-admin';

import Inbox from '@material-ui/icons/Inbox';
import Typography from '@material-ui/core/Typography';

import {
  Chip,
  TextField,
  Button,
} from '@material-ui/core';

import blackListStyles from './styles';
import BlacklistDialog from './BlacklistDialog';


const BlackListData = ({ data, onRemove }) => {
  const classes = blackListStyles();
  const [list, setList] = useState(data.items);
  const [input, setInput] = useState('');
  const translate = useTranslate();
  useEffect(() => {
    setList(data.items);
  }, [data]);

  const onChangeHandler = (e) => {
    const filterList = data.items.filter(d => e.target.value === '' || d.includes(e.target.value));
    setInput(e.target.value);
    setList(filterList);
  };
  if (!list) {
    return (
      <div className={classes.message}>
        <Inbox className={classes.icon} />
        <Typography variant="h4" paragraph>
          {translate('ra.page.empty', { name: 'blacklist' })}
        </Typography>
        <Typography variant="body1">
          {translate('ra.page.invite')}
        </Typography>
      </div>
    );
  }

  return (
    <>
      <TextField value={input} label={translate('blacklist.filter')} onChange={e => onChangeHandler(e)} />
      <div className={classes.tagContainer}>
        { (list) && list.map(word => (
          <Chip
            key={word}
            variant="outlined"
            size="small"
            label={word}
            onDelete={() => onRemove(word)}
            color="primary"
            className={classes.tag}
            onClick={() => onRemove(word)}
          />
        ))}
      </div>
    </>

  );
};


const BlackList = () => {
  const translate = useTranslate();
  const dataProvider = useDataProvider();
  const [resource, setResource] = useState();
  const [error, setError] = useState();
  const [loading, setLoading] = useState(true);
  const [showDialog, setShowDialog] = useState(false);

  const [selectedWord, setSelectedWord] = useState('');
  const [formDialog, setFormDialog] = useState('create');
  useEffect(() => {
    dataProvider.getOne('blackList')
      .then(({ data }) => {
        setResource(data);
        setLoading(false);
      })
      .catch((err) => {
        const errMessage = err.graphQLErrors.map(({ message }) => (
          message
        ));
        setError(errMessage);
        setLoading(false);
      });
  }, [dataProvider]);


  const handleRemoveItem = (word) => {
    setSelectedWord(word);
    setShowDialog(true);
    setFormDialog('remove');
  };

  const handleCreateItem = () => {
    setShowDialog(true);
    setFormDialog('create');
  };

  const handleHideDialog = () => {
    setSelectedWord('');
    setShowDialog(false);
  };

  const handleSubmit = (updatedData) => {
    setResource(updatedData);
    setShowDialog(false);
    handleHideDialog();
  };

  if (loading) return <Loading />;
  if (error) return <div>{error}</div>;
  if (!resource) return null;

  return (
    <div>
      <BlackListData data={resource} onRemove={word => handleRemoveItem(word)} />
      <Button size="small" onClick={() => handleCreateItem()} variant="contained" color="primary">{translate('blacklist.add')}</Button>
      <BlacklistDialog
        formType={formDialog}
        showDialog={showDialog}
        selectedWord={selectedWord}
        record={resource}
        onSubmit={data => handleSubmit(data)}
        onCancel={() => handleHideDialog()}
      />
    </div>
  );
};

export default BlackList;
