import React from 'react';
import {
  Accordion, AccordionSummary, AccordionDetails, Typography,
} from '@material-ui/core';
import { useTranslate } from 'react-admin';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import InfoIcon from '@material-ui/icons/Info';
import { jobStatusLegendStyles } from './JobStatusLegendStyles';

const JobStatusLegend = () => {
  const translate = useTranslate();
  const classes = jobStatusLegendStyles();

  return (
    <Accordion className={classes.container}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <InfoIcon color="primary" /> <Typography className={classes.heading}>{translate('products.jobsStatusLegend.title')}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <div>
          <Typography color="primary" className={classes.statusTitle}>{translate('products.jobsStatusLegend.created.title')}:</Typography>
          <Typography>{translate('products.jobsStatusLegend.created.desc')}</Typography>
        </div>
      </AccordionDetails>
      <AccordionDetails>
        <div>
          <Typography color="primary" className={classes.statusTitle}>{translate('products.jobsStatusLegend.started.title')}:</Typography>
          <Typography>{translate('products.jobsStatusLegend.started.desc')}</Typography>
        </div>
      </AccordionDetails>
      <AccordionDetails>
        <div>
          <Typography color="primary" className={classes.statusTitle}>{translate('products.jobsStatusLegend.finished.title')}:</Typography>
          <Typography>{translate('products.jobsStatusLegend.finished.desc')}</Typography>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

export default JobStatusLegend;
