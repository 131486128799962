import * as React from 'react';
import {
  Create, required, SimpleForm, TextInput,
} from 'react-admin';

const validateName = [required()];


const ManufacturerCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" validate={validateName} />
      <TextInput source="description" multiline />
    </SimpleForm>
  </Create>
);
export default ManufacturerCreate;
