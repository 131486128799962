const BULK_JOBS_LIST = `
    query bulkJobs($pagination: PaginationInput, $filter:BulkJobsFilterInput ) {
        bulkJobs(pagination: $pagination, filter:$filter) {
            pagination {
              count
              pages
              next
              prev
            }
            results {
              id
              status
              progress
              startedAt
              finishedAt
            }
        }
    }
`;

export default BULK_JOBS_LIST;
