import React, { useEffect, useState } from 'react';
import { downloadCSV, ListButton, useNotify } from 'react-admin';
import { decode } from 'html-entities';
import moment from 'moment';
import jsonExport from 'jsonexport/dist';

import gql from 'graphql-tag';
import GetAppIcon from '@material-ui/icons/GetApp';
import PRODUCTS from '../../providers/queries/products';
import client from '../../providers/client';

const zero = 0;
const page = 1;
const defaultLimit = 5000;
const commaCharacter = ',';
const replaceCommaBy = '|&|';
const semicolonCharacter = ';';

const ProductExporter = (props) => {
  const { filter, search } = props;
  const notify = useNotify();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const limit = filter?.eans?.length || defaultLimit;

  const handleClick = () => {
    setLoading(true);
    const variables = { pagination: { page, limit }, filter, search };
    const query = gql`${PRODUCTS}`;

    client.query({ query, variables }).then(
      (result) => {
        const { data: json } = result;

        if (result?.errors) {
          notify(result?.errors.pop().message, 'warning');
        } else {
          const { products } = json;
          const { results } = products;

          setData(results);
        }
      },
    ).catch((err) => {
      notify(err.message, 'warning');
    });
  };

  const encodeEspecialChars = (text) => {
    if (text !== null && text !== undefined) {
      return text.replaceAll(semicolonCharacter, commaCharacter)
        .replaceAll(commaCharacter, replaceCommaBy)
        .replace(/\r?\n|\r/g, '');
    }

    return null;
  };

  const parseAllergens = (allergens) => {
    const output = {
      milk: '',
      soybeans: '',
      peanuts: '',
      celery: '',
      eggs: '',
      molluscs: '',
      tree_nuts: '',
      fish: '',
      mustard: '',
      sesame_seeds: '',
      sulfur_dioxide_and_sulfits: '',
      lupine: '',
      crustaceans: '',
      gluten: '',
    };

    if (allergens !== null && allergens !== undefined) {
      allergens.forEach((item) => {
        const { allergen, value } = item;
        const { code } = allergen;

        const key = code.replaceAll(' ', '_');

        output[key] = value.toLowerCase();
      });
    }

    return output;
  };

  const parseNutritions = (nutritions) => {
    const output = {
      energy: '',
      sugar: '',
      fat: '',
      salt: '',
      protein: '',
      fiber: '',
    };

    if (nutritions !== null && nutritions !== undefined) {
      nutritions.forEach((item) => {
        const { code, quantity } = item;

        const key = code.toLowerCase();

        output[key] = quantity;
      });
    }

    return output;
  };

  const parseLabels = (labels) => {
    if (labels === []) {
      return '';
    }
    const output = [];

    labels.forEach((item) => {
      output.push(item.name);
    });

    return output.join(',');
  };

  useEffect(() => {
    if (data !== null && data?.length > zero) {
      const toExport = data.map((product) => {
        const {
          ean, name, description, brand, category, composition, images, labels, packaging,
        } = product;

        const allergens = parseAllergens(composition?.nutritionInformation?.allergens);
        const nutritions = parseNutritions(composition?.nutritionInformation?.contents);
        const parsedLabels = parseLabels(labels);

        return {
          action: 'update',
          ean,
          name: decode(encodeEspecialChars(name)),
          description: description !== null && description !== undefined ? decode(encodeEspecialChars(description)) : '',
          brand: decode(encodeEspecialChars(brand?.name)) || '',
          category_id: category?.id || '',
          composition: composition?.text !== null && composition?.text !== undefined ? decode(encodeEspecialChars(composition?.text)) : '',
          group: composition?.group?.code || '',
          nutriscore: composition?.nutritionInformation?.nutriscore || '',
          allergen_milk: allergens.milk || '',
          allergen_soybeans: allergens.soybeans || '',
          allergen_peanuts: allergens.peanuts || '',
          allergen_celery: allergens.celery || '',
          allergen_eggs: allergens.eggs || '',
          allergen_molluscs: allergens.molluscs || '',
          allergen_tree_nuts: allergens.tree_nuts || '',
          allergen_fish: allergens.fish || '',
          allergen_mustard: allergens.mustard || '',
          allergen_sesame_seeds: allergens.sesame_seeds || '',
          allergen_sulfur_dioxide_and_sulfits: allergens.sulfur_dioxide_and_sulfits || '',
          allergen_lupine: allergens.lupine || '',
          allergen_crustaceans: allergens.crustaceans || '',
          allergen_gluten: allergens.gluten || '',
          nutrition_energy: nutritions.energy || '',
          nutrition_sugar: nutritions.sugar || '',
          nutrition_fat: nutritions.fat || '',
          nutrition_salt: nutritions.salt || '',
          nutrition_protein: nutritions.protein || '',
          nutrition_fiber: nutritions.fiber || '',
          image_url: images[zero]?.url || '',
          labels: encodeEspecialChars(parsedLabels) || '',
          packaging: encodeEspecialChars(packaging) || '',
        };
      });

      jsonExport(toExport, {
        headers: [
          'action',
          'ean',
          'name',
          'description',
          'brand',
          'category_id',
          'composition',
          'group',
          'nutriscore',
          'allergen_milk',
          'allergen_soybeans',
          'allergen_peanuts',
          'allergen_celery',
          'allergen_eggs',
          'allergen_molluscs',
          'allergen_tree_nuts',
          'allergen_fish',
          'allergen_mustard',
          'allergen_sesame_seeds',
          'allergen_sulfur_dioxide_and_sulfits',
          'allergen_lupine',
          'allergen_crustaceans',
          'allergen_gluten',
          'nutrition_energy',
          'nutrition_sugar',
          'nutrition_fat',
          'nutrition_salt',
          'nutrition_protein',
          'nutrition_fiber',
          'image_url',
          'labels',
          'packaging',
        ],
        rename: [
          'action',
          'ean',
          'name',
          'description',
          'brand',
          'category_id',
          'composition',
          'group',
          'nutriscore',
          'allergen:milk',
          'allergen:soybeans',
          'allergen:peanuts',
          'allergen:celery',
          'allergen:eggs',
          'allergen:molluscs',
          'allergen:tree_nuts',
          'allergen:fish',
          'allergen:mustard',
          'allergen:sesame_seeds',
          'allergen:sulfur_dioxide_and_sulfits',
          'allergen:lupine',
          'allergen:crustaceans',
          'allergen:gluten',
          'nutrition:energy',
          'nutrition:sugar',
          'nutrition:fat',
          'nutrition:salt',
          'nutrition:protein',
          'nutrition:fiber',
          'image_url',
          'labels',
          'packaging',
        ],
      }, (err, csv) => {
        const exportDate = moment().format('YYYYMMDD_HHmmss');
        downloadCSV(csv?.replace(/,/g, ';').replaceAll(replaceCommaBy, commaCharacter), `products_export_${exportDate}`);
        setLoading(false);
      });
    }
  }, [data]);

  return (
    <ListButton
      style={{ marginRight: '5px' }}
      onClick={handleClick}
      label="Download products info"
      icon={<GetAppIcon />}
      disabled={loading}
    />
  );
};

export default ProductExporter;
