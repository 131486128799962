import React from 'react';
import {
  Datagrid, TextField, Pagination, useTranslate,
} from 'react-admin';
import { Box, Button } from '@material-ui/core';
import TemplateList from '../../../lib/components/TemplateList';

const StepTwo = (props) => {
  const {
    eans, setAction, actions, setActiveStep, PROGRESS,
  } = props;
  const translate = useTranslate();
  const globalFont = { fontFamily: 'Noto Sans, Arial, Helvetica Neue, Helvetica, sans-serif' };

  return (
    <div>
      <h3 style={{ ...globalFont }}>{translate('products.syndication.stepTwo.title')}</h3>
      <TemplateList basePath="products" resource="products" filter={{ eans }}>
        <Datagrid>
          <TextField source="ean" />
          <TextField source="name" />
          <TextField source="brand" />
        </Datagrid>
        <Pagination />
        <Box style={{
          marginTop: '20px', display: 'flex', flexFlow: 'row wrap', justifyContent: 'space-around',
        }}
        >
          <Button
            onClick={() => {
              setActiveStep(PROGRESS.INSERT);
            }}
            variant="contained"
            color="primary"
          >
            {translate('products.syndication.steps.prev')}
          </Button>
          <Button
            onClick={() => {
              setActiveStep(PROGRESS.END);
              setAction(actions.SYNDICATION);
            }}
            variant="contained"
            color="primary"
          >
            {translate('products.syndication.stepTwo.buttonSyndication')}
          </Button>
          <Button
            onClick={() => {
              setActiveStep(PROGRESS.END);
              setAction(actions.UNSYNDICATION);
            }}
            variant="contained"
            color="primary"
          >
            {translate('products.syndication.stepTwo.buttonUnSyndication')}
          </Button>
        </Box>
      </TemplateList>
    </div>

  );
};

export default StepTwo;
