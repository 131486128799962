import * as React from 'react';
import {
  Edit, SimpleForm, TextInput, DateInput, useNotify, required,
} from 'react-admin';

const validateName = [required()];

const EditManufacturer = (props) => {
  const notify = useNotify();
  const onSuccess = () => {
    notify('manufacturer.edit.updated', 'success');
  };
  return (
    <Edit
      {...props}
      redirect="edit"
      onSuccess={onSuccess}
      mutationMode="pessimistic"
    >
      <SimpleForm>
        <TextInput source="name" validate={validateName} />
        <TextInput source="description" multiline />
        <DateInput disabled source="createdAt" />
        <DateInput disabled source="updatedAt" />
      </SimpleForm>
    </Edit>
  );
};

export default EditManufacturer;
