const PRODUCTS = `
    query Products($pagination: PaginationInput, $filter:ProductsFilterInput, $search:String  ) {
        products(pagination: $pagination, filter:$filter, search:$search) {
            pagination {
              count
              pages
              next
              prev
            }
            results {
              id
              ean
              name
              active
              brand {
                name
              }
              category {
                id
              }
              description
              packaging
              createdAt
              updatedAt
              images {
                id
                url
              }
              labels {
                id
                name
              }
              composition {
                  text
                  group {
                    id
                    code
                  }
                  nutritionInformation {
                    allergens {
                      allergen {
                        code
                      }
                      value
                      label
                    }
                    contents {
                      name
                      code
                      group {
                        id
                        code
                      }
                      quantity
                    }
                    nutriscore
                  }
              }
            }
        }
    }
`;

export default PRODUCTS;
