const BULK_BATCH_JOBS_LIST = `
    query batchJobs($pagination: PaginationInput, $filter:BulkJobsFilterInput, $filterParts:BulkJobPartsFilterInput ) {
        bulkJobParts(pagination: $pagination, filter: $filterParts) {
          results {
            errors
            id
            key
            status
          }
        }
        batchJobs(pagination: $pagination, filter:$filter) {
            pagination {
              count
              pages
              next
              prev
            }
            results {
              fileName
              finishedAt
              id
              progress
              startedAt
              status
              task
            }
        }
    }
`;

export default BULK_BATCH_JOBS_LIST;
