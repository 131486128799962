import { makeStyles } from '@material-ui/core/styles';

export const jobStatusLegendStyles = makeStyles(() => ({
  container: {
    width: '100%',
    borderRadius: '4px',
  },
  heading: {
    fontSize: '16px',
    fontWeight: 'bold',
    marginLeft: '10px',
  },
  statusTitle: {
    fontWeight: 'bold',
  },
}));

export default jobStatusLegendStyles;
