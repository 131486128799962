import React from 'react';
import { Card } from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';
import { TopToolbar } from 'react-admin';
import UploadCsv from '../../lib/components/uploadCsv';

const ShowActions = () => (
  <TopToolbar>
    <UploadCsv />
  </TopToolbar>
);
const ShufflePage = () => (
  <Card>
    <CardContent>
      <ShowActions />
    </CardContent>
  </Card>
);

export default ShufflePage;
