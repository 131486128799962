import {
  BooleanInput, Button,
  DateInput, FormDataConsumer,
  Loading,
  TextInput,
  useTranslate,
} from 'react-admin';
import React from 'react';
import Box from '@material-ui/core/Box';
import { useLoading } from 'ra-core';
import { useForm } from 'react-final-form';
import { makeStyles } from '@material-ui/core/styles';
import UserValidation from './UserValidation';
import EditUserBtn from './buttons/EditUserBtn';
import DeleteBtn from './buttons/DeleteBtn';
import { MALRG_BO_URL } from '../../lib/utils';


const useStyles = makeStyles(() => ({
  button: {
    minWidth: 132,
    marginTop: '20px',
    marginLeft: '30px',
  },
}));
const UserDetailTabForm = () => {
  const translate = useTranslate();
  const loading = useLoading();
  const classes = useStyles();
  const cardButton = 'CARD';
  const profileButton = 'PROFILE';

  const { change } = useForm();
  const handleLink = (button, legacyId) => {
    const marlgHost = MALRG_BO_URL();
    const urlProfile = `index.php?option=com_userscustom&view=panelist&task=edit&cid%5B%5D=${legacyId}`;
    const urlCards = `index.php?pshop_mode=admin&page=redenciones.abusos&option=com_virtuemart&filtro_abusos%5Buser_id%5D=${legacyId}`;

    if (button === cardButton) {
      return window.open(marlgHost + urlCards, '_blank');
    }
    return window.open(marlgHost + urlProfile, '_blank');
  };
  if (loading) {
    return <Loading />;
  }
  return (
    <Box style={{ display: 'flex', flexDirection: 'column' }}>
      <TextInput
        source="username"
        validate={UserValidation.validateUsername}
        resettable
        onClick={() => { change('username', ''); }}
        label={translate('user.edit.username')}
      />
      <TextInput
        source="name"
        validate={UserValidation.validateName}
        onClick={() => { change('name', ''); }}
        resettable
        label={translate('user.edit.name')}
      />
      <TextInput
        source="lastName"
        onClick={() => { change('lastName', ''); }}
        resettable
        label={translate('user.edit.lastname')}
      />
      <BooleanInput
        source="active"
        label={translate('user.edit.activated')}
      />
      <BooleanInput
        source="isAdmin"
        label={translate('user.edit.isAdmin')}
      />
      <BooleanInput
        source="communicationsPolicy"
        label={translate('user.edit.communication')}
      />
      <BooleanInput
        source="shuffleConnectionPolicy"
        label={translate('user.edit.shuffleConnection')}
      />
      <BooleanInput
        source="conditions"
        disabled
        label={translate('user.edit.conditions')}
      />
      <TextInput
        source="legacyContext.externalUserId"
        disabled
        label={translate('user.edit.externalId')}
      />
      <div>
        <TextInput
          source="legacyContext.monavisId"
          disabled
          label={translate('user.edit.monavisId')}
        />
        <FormDataConsumer>
          {({ formData }) => (
            <Button variant="outlined" className={classes.button} onClick={() => handleLink(profileButton, formData.legacyContext.monavisId)} label="user.form.link_1" />
          )}
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData }) => (
            <Button variant="outlined" className={classes.button} onClick={() => handleLink(cardButton, formData.legacyContext.monavisId)} label="user.form.link_2" />
          )}
        </FormDataConsumer>

      </div>
      <TextInput
        source="registeredFromApp"
        disabled
        label={translate('user.edit.registeredFromApp')}
      />
      <DateInput
        source="createdAt"
        disabled
        label={translate('user.edit.createdAt')}
      />
      <DateInput
        source="updatedAt"
        disabled
        label={translate('user.edit.updatedAt')}
      />
      <DateInput
        source="activatedAt"
        disabled
        label={translate('user.edit.activatedAt')}
      />
      <DateInput
        source="lastAccessedAt"
        disabled
        label={translate('user.edit.lastAccessedAt')}
      />
      <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <EditUserBtn
          customLoad={() => {}}
          customProfile={() => {}}
          errorHomeCom={() => {}}
        />
        <DeleteBtn
          customLoad={() => {}}
          errorHomeCom={() => {}}
        />
      </Box>
    </Box>
  );
};

export default UserDetailTabForm;
