const BULK_JOB = `
    query bulkJobs($pagination: PaginationInput, $filter:BulkJobsFilterInput ) {
        bulkJobs(pagination: $pagination, filter:$filter) {
            pagination {
              count
              pages
              next
              prev
            }
            results {
              id
              status
              progress
              parts {
                id
                key
                status
                errors
              }
              startedAt
              finishedAt
            }
        }
    }
`;

export default BULK_JOB;
