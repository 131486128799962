import React from 'react';

import {
  Datagrid, List, TextField, DateField, Filter, TextInput, useTranslate,
} from 'react-admin';
import FullNameText from '../../lib/components/FullNameText';
import {
  CRF_CARD, CRF_PREFIX, isValidLuhn, PASS_CARD, PASS_PREFIX,
} from '../../lib/utils/cards';


const CardFilter = (props) => {
  const translate = useTranslate();
  const error = translate('user.cards.luhn');
  const validateLuhn = (value) => {
    if (!value) {
      return undefined;
    }
    if (value.length === CRF_CARD && !value.startsWith(CRF_PREFIX)) {
      return error;
    }
    if (value.length === PASS_CARD && !value.startsWith(PASS_PREFIX)) {
      return error;
    }

    if (!isValidLuhn(value)) {
      return error;
    }

    return undefined;
  };
  return (
    <Filter {...props} data-testid="card-search">
      <TextInput
        source="code"
        label="Card Number"
        validate={validateLuhn}
        alwaysOn
      />
    </Filter>
  );
};

const CardList = props => (
  <List
    {...props}
    bulkActionButtons={false}
    exporter={false}
    filters={<CardFilter {...props} />}
  >
    <Datagrid>
      <TextField source="code" label="Card Number" />
      <TextField source="type" />
      <FullNameText label="Name and Lastname" />
      <TextField source="user.username" label="Username" />
      <DateField source="createdAt" />
    </Datagrid>
  </List>
);

export default CardList;
