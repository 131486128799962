import React, { useState, Fragment } from 'react';
import { ListButton, useTranslate } from 'react-admin';
import {
  Box, Card, Step, StepLabel, Stepper,
} from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';

import StepOne from './syndication/StepOne';
import StepTwo from './syndication/StepTwo';
import StepThree from './syndication/StepThree';

import { SYNDICATION_ACTION_ADD, SYNDICATION_ACTION_DEL, SYNDICATION_CLIENT_DEFAULT } from '../../lib/utils/products';

const Syndication = () => {
  const translate = useTranslate();
  const initialStep = 0;
  const initialErrorStep = -1;
  const PROGRESS = {
    INSERT: 0,
    SELECT_ACTION: 1,
    END: 2,
  };
  const ACTIONS = {
    SYNDICATION: SYNDICATION_ACTION_ADD,
    UNSYNDICATION: SYNDICATION_ACTION_DEL,
  };
  const steps = [
    translate('products.syndication.steps.1'),
    translate('products.syndication.steps.2'),
    translate('products.syndication.steps.3'),
  ];
  const client = SYNDICATION_CLIENT_DEFAULT;
  const [activeStep, setActiveStep] = useState(initialStep);
  const [errorStep, setErrorStep] = useState(initialErrorStep);
  const [action, setAction] = useState('');
  const [eans, setEans] = useState('');
  const isStepFailed = step => step === errorStep;

  return (
    <Fragment>
      <Box display="flex" justifyContent="space-between" style={{ height: '40px' }}>
        <Box flex={1} style={{ marginRight: '10px', minWidth: '120px', alignSelf: 'center' }}>
          <ListButton basePath="/products" />
        </Box>
      </Box>
      <Card>
        <CardContent>
          <Box style={{ margin: '10px 0 20px' }} />
          <Stepper activeStep={activeStep}>
            {steps.map((label, index) => {
              const labelProps = {};
              if (isStepFailed(index)) {
                labelProps.error = true;
              }

              return (
                <Step key={label}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
          <Box style={{ margin: '10px 0 20px' }}>
            {activeStep === PROGRESS.INSERT
              && (
              <StepOne
                eans={eans}
                setEans={setEans}
                setErrorStep={setErrorStep}
                setActiveStep={setActiveStep}
                PROGRESS={PROGRESS}
              />
              )}
            {activeStep === PROGRESS.SELECT_ACTION
            && (
              <StepTwo
                actions={ACTIONS}
                eans={eans}
                setAction={setAction}
                setActiveStep={setActiveStep}
                PROGRESS={PROGRESS}
              />
            )}
            {activeStep === PROGRESS.END
            && (
              <StepThree
                action={action}
                eans={eans}
                client={client}
                setActiveStep={setActiveStep}
                PROGRESS={PROGRESS}
              />
            )}
          </Box>
        </CardContent>
      </Card>
    </Fragment>
  );
};

export default Syndication;
