import React, { useLayoutEffect, useState } from 'react';

import Box from '@material-ui/core/Box';
import {
  Loading, useDataProvider, useNotify, useTranslate,
} from 'react-admin';
import { useLoading } from 'ra-core';
import AddCard from './AddCard';
import ShowCard from './ShowCard';


const UserCards = ({ record }) => {
  const [card, setCard] = useState(null);
  const [showCard, setShowCard] = useState(null);
  const dataProvider = useDataProvider();
  const loading = useLoading();
  const notify = useNotify();
  const { translate } = useTranslate;
  const zero = 0;


  useLayoutEffect(() => {
    if (record) {
      dataProvider.getList('cards', {
        pagination: {
          page: 1,
          limit: 14,
        },
        filter: {
          userId: record?.id,
        },
      }).then((res) => {
        const { total, data } = res;
        if (total > zero) {
          return setCard(data[zero]);
        }
        return setCard(null);
      }).catch((error) => {
        notify(translate('user.cards.errorQuery'), 'error');
        notify(error, 'error');
      });
    }
  }, [record, dataProvider]);
  useLayoutEffect(() => {
    const isCardAvailable = card !== null;
    setShowCard(isCardAvailable);
  }, [card]);
  if (!record || loading) {
    return <Loading />;
  }
  return (
    <Box sx={{ margin: '20px', boxSizing: 'border-box' }}>
      {!showCard && <AddCard record={record} setCard={setCard} />}
      {showCard && card && <ShowCard card={card} setCard={setCard} />}
    </Box>
  );
};

export default UserCards;
