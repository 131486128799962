import { makeStyles } from '@material-ui/core/styles';

export const jobDetailStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    width: '100%',
    margin: '25px 0',
  },
  cell: {
    flex: '0 1 auto',
    width: '50%',
    fontFamily: 'Noto Sans, Arial, Helvetica Neue, Helvetica, sans-serif',
  },
}));

export default jobDetailStyles;
