import React, { useState } from 'react';
import _ from 'lodash';
import {
  useTranslate,
  useNotify,
  useDataProvider,
} from 'react-admin';

import {
  TextField,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
} from '@material-ui/core';

const NO_EXIST_INDEX = -1;

const BlacklistDialog = (props) => {
  const {
    formType, record, showDialog, onSubmit, onCancel, selectedWord,
  } = props;
  const translate = useTranslate();
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const [newWord, setNewWord] = useState('');
  const [loading, setLoading] = useState(false);

  const saveData = (value) => {
    setLoading(true);
    return dataProvider
      .update('blackList', { input: { value } })
      .then(({ data }) => {
        setLoading(false);
        if (data) {
          notify(translate('ra.notification.updated', { smart_count: 1 }), 'success');
          onSubmit(data);
          setNewWord('');
        } else {
          notify(translate('ra.notification.canceled'), 'warning');
        }
      })
      .catch((err) => {
        setLoading(false);
        notify(`${err.message}`, 'warning');
      });
  };

  const handleCreate = () => {
    const existingWord = (record.items.indexOf(newWord) > NO_EXIST_INDEX);
    if (existingWord) {
      notify(translate('blacklist.existingTerm'), 'warning');
    } else {
      const newRecord = [...record.items, newWord];
      saveData(newRecord);
    }
  };

  const handleRemove = () => {
    const newRecord = _.remove(record.items, n => n !== selectedWord);
    saveData(newRecord);
    return false;
  };

  const handleCancel = () => {
    setNewWord('');
    onCancel();
  };

  const title = (formType === 'remove')
    ? translate('blacklist.questionRemove', { selectedWord })
    : translate('blacklist.addQuestion');

  return (
    <Dialog
      open={showDialog}
      onClose={handleCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      {
        formType !== 'remove'
        && (
        <DialogContent>
          <TextField label={translate('blacklist.add')} onChange={e => setNewWord(e.target.value)} value={newWord} />
        </DialogContent>
        )
      }
      <DialogActions>
        <Button onClick={handleCancel} color="default">
          {translate('blacklist.cancel')}
        </Button>
        <Button onClick={() => ((formType === 'remove') ? handleRemove() : handleCreate())} color="primary" disabled={loading} autoFocus>
          {translate('blacklist.confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default BlacklistDialog;
