import React, { useState } from 'react';
import {
  Button,
  useNotify,
  useTranslate, FileField, FileInput, SimpleForm,
} from 'react-admin';
import IconContentAdd from '@material-ui/icons/Add';
import IconCancel from '@material-ui/icons/Cancel';
import IconSave from '@material-ui/icons/Save';
import IconDelete from '@material-ui/icons/DeleteForever';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { makeStyles } from '@material-ui/core/styles';
import {
  FormControl, Box, Stepper, Step, StepLabel,
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import gql from 'graphql-tag';
import CardContent from '@material-ui/core/CardContent';
import LinearProgress from '@material-ui/core/LinearProgress';
import client from '../../providers/client';
import UPLOAD_CSV_BULK_JOB from '../../providers/mutations/uploadCsvBulkJob';
import { CSV_SHUFFLE_TEMPLATE_URL, CSV_SHUFFLE_TEMPLATE_URL_DELETE } from '../utils';
import ShowBatchJobStatus from '../../resources/products/ShowBatchJobStatus';

const useStyles = makeStyles(() => ({
  button: {
    minWidth: 132,
  },
}));

const UploadCsv = () => {
  const maxSize = 2000000;
  const taskCreate = 'CREATE_SHUFFLE_RELATION';
  const taskDelete = 'DELETE_SHUFFLE_RELATION';
  const PROGRESS = {
    INITIAL: 0,
    UPLOADING_FILE: 5,
    PROCESSING_FILE: 10,
    END: 100,
  };
  const initialStep = 0;
  const zero = 0;
  const initialErrorStep = -1;
  const [showDialog, setDialogue] = useState(false);
  const [file, setFile] = useState();
  const [task, setTask] = useState(taskCreate);
  const [status, setStatus] = useState('');
  const [currentJob, setCurrentJob] = useState(null);
  const [activeStep, setActiveStep] = useState(initialStep);
  const [errorStep, setErrorStep] = useState(initialErrorStep);
  const [progress, setProgress] = useState(PROGRESS.INITIAL);
  const [showProgress, setShowProgress] = useState(false);
  const notify = useNotify();
  const translate = useTranslate();
  const classes = useStyles();
  const globalFont = { fontFamily: 'Noto Sans, Arial, Helvetica Neue, Helvetica, sans-serif' };
  const megas = 1000000;
  const MegasSize = maxSize ? parseInt(maxSize, 10) / megas : false;
  const STATUS = {
    UPLOADING_FILE: translate('products.uploads.processing.status.upload'),
    PROCESSING_FILE: translate('products.uploads.processing.status.process'),
    END: translate('products.uploads.processing.status.end'),
  };
  const steps = [STATUS.UPLOADING_FILE, STATUS.PROCESSING_FILE, STATUS.END];
  const isStepFailed = step => step === errorStep;
  const setEndWithError = (msg, stepWithError) => {
    setProgress(PROGRESS.END);
    setCurrentJob(null);
    setStatus(msg);
    setErrorStep(steps.indexOf(stepWithError));
  };
  const setEnd = () => {
    setProgress(PROGRESS.END);
    setStatus(STATUS.END);
    setActiveStep(steps.indexOf(STATUS.END));
  };

  const handleChange = (changed) => {
    setFile(changed);
  };
  const clearSource = () => {
    setDialogue(false);
  };
  const approve = (file2Send) => {
    const mutation = gql`${UPLOAD_CSV_BULK_JOB}`;
    setCurrentJob(null);
    setShowProgress(true);
    setProgress(PROGRESS.INITIAL);
    setProgress(PROGRESS.UPLOADING_FILE);
    setStatus(STATUS.UPLOADING_FILE);
    setActiveStep(steps.indexOf(STATUS.UPLOADING_FILE));
    client.mutate({ mutation, variables: { file: file2Send, task } })
      .then((res) => {
        const { data } = res;
        const { uploadCsvToCreateBatchJob } = data;
        if (uploadCsvToCreateBatchJob === null) {
          const { errors } = res;
          const errorMsg = errors[zero].message;
          return setEndWithError(errorMsg, STATUS.UPLOADING_FILE);
        }
        notify(translate('products.shuffle.success'), 'success');
        setDialogue(false);
        const { bulkJobId } = uploadCsvToCreateBatchJob;
        setCurrentJob(bulkJobId);
        setStatus(STATUS.PROCESSING_FILE);
        setActiveStep(steps.indexOf(STATUS.PROCESSING_FILE));
        setProgress(PROGRESS.PROCESSING_FILE);

        return true;
      })
      .catch(() => {
        notify(translate('products.shuffle.error'), 'error');
      });
  };

  return (
    <div style={{ width: '100%' }}>
      <CardContent>
        <h3 style={{ ...globalFont }}>
          {translate('products.shuffle.title')}
        </h3>
        <Typography>
          {translate('products.shuffle.file')}
          <FileField
            record={{ url: CSV_SHUFFLE_TEMPLATE_URL() }}
            source="url"
            title={translate('products.shuffle.fileClaim')}
            style={{ ...globalFont }}
          />
        </Typography>
        <Typography>
          {translate('products.shuffle.fileDelete')}
          <FileField
            record={{ url: CSV_SHUFFLE_TEMPLATE_URL_DELETE() }}
            source="url"
            title={translate('products.shuffle.fileClaim')}
            style={{ ...globalFont }}
          />
        </Typography>
        <Box />
        <FormControl style={{ flexDirection: 'row' }}>
          <Button
            className={classes.button}
            variant="outlined"
            onClick={() => {
              setDialogue(true);
              return setTask(taskCreate);
            }}
            label={translate('products.shuffle.btn')}
          >
            <IconContentAdd />
          </Button>
          <Button
            className={classes.button}
            style={{ marginLeft: '5px' }}
            variant="outlined"
            onClick={() => {
              setDialogue(true);
              return setTask(taskDelete);
            }}
            label={translate('products.shuffle.delete')}
          >
            <IconDelete />
          </Button>
        </FormControl>
      </CardContent>
      <Dialog fullWidth open={showDialog} onClose={() => clearSource()} aria-label={translate('products.shuffle.aria')}>
        <DialogTitle>Add csv</DialogTitle>
        <DialogContent>
          <Typography><b>Format:</b> text/csv</Typography>
          {MegasSize && <Typography><b>Maximum weight</b> {MegasSize}Mb</Typography>}
          <Typography><b>The maximum number of EANs to process is</b> 20.000</Typography>
          <SimpleForm toolbar={null}>
            <FileInput onChange={csv => handleChange(csv)} source="files" label={translate('products.shuffle.label')} accept="text/csv">
              <FileField source="src" title="title" />
            </FileInput>
          </SimpleForm>
        </DialogContent>
        <DialogActions>

          <Button
            label="ra.action.save"
            disabled={!file}
            onClick={() => approve(file)}
          >
            <IconSave />
          </Button>
          <Button onClick={() => clearSource()} label="ra.action.cancel">
            <IconCancel />
          </Button>
        </DialogActions>
      </Dialog>
      { showProgress ? (
        <>
          <h3 style={{ ...globalFont }}>
            {translate('products.uploads.processing.title')}
          </h3>
          <Stepper activeStep={activeStep}>
            {steps.map((label, index) => {
              const labelProps = {};
              if (isStepFailed(index)) {
                labelProps.error = true;
              }
              return (
                <Step key={label}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
          <LinearProgress variant="determinate" size={25} thickness={2} value={progress} />
          <Box
            style={{ ...globalFont, marginTop: '10px' }}
            component="div"
          >
            {translate('products.uploads.processing.span')}: {status}
          </Box>
          {status !== STATUS.UPLOADING_FILE && currentJob
            ? (
              <ShowBatchJobStatus
                jobId={currentJob}
                setEndWithError={setEndWithError}
                setEnd={setEnd}
                setProgress={setProgress}
                STATUS={STATUS}
              />
            )
            : null}
        </>
      ) : null}
    </div>
  );
};

export default UploadCsv;
