import React from 'react';
import { useTranslate, useEditController, Loading } from 'react-admin';

import { makeStyles } from '@material-ui/core/styles';

import UserDetailTab from './UserDetailTab';
import UserDetailProfile from './UserDetailProfile';
import OrderList from '../orders/OrderReferenceList';
import UserPointsTab from './UserPointsTab';
import UserAdditionalPointsTab from './UserAdditionalPointsTab';

import { TabbedLayout, Tab } from '../../lib/components';
import UserCards from './cards/UserCards';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  content: {
    paddingLeft: 0,
    paddingRight: 0,
  },
}));

let recordData;

const CustomEdit = (props) => {
  const { record } = useEditController(props);
  const translate = useTranslate();
  const classes = useStyles();
  recordData = record;

  return (
    <div className={classes.root}>
      <TabbedLayout>
        <Tab label={translate('user.tabs.user')} className={classes.content}>
          <UserDetailTab {...props} />
        </Tab>
        <Tab label={translate('user.tabs.profile')} className={classes.content} path="profile">
          <UserDetailProfile {...props} />
        </Tab>
        <Tab label={translate('user.tabs.orders')} path="orders">
          {
            !recordData && <Loading />
          }
          {recordData && recordData?.username
            && <OrderList username={recordData?.username} {...props} />
          }
        </Tab>
        <Tab label={translate('user.tabs.points')} path="points">
          <UserPointsTab record={record} />
        </Tab>
        <Tab label={translate('user.tabs.additionalPoints')} path="additional-points">
          <UserAdditionalPointsTab record={record} />
        </Tab>
        <Tab label={translate('user.tabs.cards')} path="loyalty-cards">
          <UserCards record={recordData} />
        </Tab>
      </TabbedLayout>
    </div>
  );
};

const UserEdit = ({ permissions, ...props }) => (
  <CustomEdit {...props} undoable={false} />

);
export default UserEdit;
