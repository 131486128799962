import { makeStyles } from '@material-ui/core/styles';

const blackListStyles = makeStyles(theme => ({
  tagContainer: {
    margin: '20px 0 20px 0',
  },
  tag: {
    margin: '4px 5px 4px 0',
  },
  loadingContainer: {
    minHeight: '100px',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  message: {
    textAlign: 'center',
    margin: '0 1em',
    color:
          theme.palette.type === 'light'
            ? 'inherit'
            : theme.palette.text.primary,
  },
  icon: {
    width: '9em',
    height: '9em',
  },
}));

export default blackListStyles;
