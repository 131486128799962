import React, { useState } from 'react';
import {
  DateInput, SimpleForm, downloadCSV, useTranslate,
} from 'react-admin';
import moment from 'moment';
import { Button } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { gql } from '@apollo/client';
import jsonExport from 'jsonexport/dist';

import { promotionFormStyles } from './promotionFormStyles';
import { PROMOTION_TYPES, REQUESTED } from './constants';
import client from '../../providers/client';

const PromotionOrdersReport = () => {
  const t = useTranslate();
  const classes = promotionFormStyles();
  const [dateFrom, setDateFrom] = useState(null);
  const [dateUntil, setDateUntil] = useState(null);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(undefined);

  const ORDERS_QUERY = gql`
    query Orders($filter: OrderFilterInput) {
      orders(filter: $filter) {
        results {
          id
          user {
            username
            name
            lastName
            profile {
              identifier
              address {
                line1
                line2
              }
            }
            telephone
          }
          requestedDate
          promotion {
            name
            eans {
              value
            }
          }
        }
      }
    }
  `;

  const ordersExporter = (data) => {
    const parsedExport = data.map((item) => {
      const {
        id, promotion, requestedDate, user,
      } = item;
      const { eans, name: promotionName } = promotion;
      const {
        lastName, name, profile, username, telephone,
      } = user;
      const { address, identifier } = profile;
      const { line1, line2 } = address;

      return {
        orderId: id,
        email: username,
        date: moment(requestedDate).local().format('DD-MM-YYYY HH:mm:ss'),
        name: `${name} ${lastName}`,
        identifier,
        telephone,
        address: `${line1} - ${line2}`,
        product: promotionName,
        ean: eans?.[0]?.value,
      };
    });

    jsonExport(parsedExport, {
      headers: [
        'orderId',
        'email',
        'date',
        'name',
        'identifier',
        'telephone',
        'address',
        'product',
        'ean',
      ],
      rename: [
        'ID de Pedido',
        'Email',
        'Fecha',
        'Nombre completo',
        'Cédula',
        'Teléfono',
        'Dirección',
        'Producto',
        'EAN',
      ],
    }, (err, csv) => {
      const plainCsv = csv?.replace(/,/g, ';');
      const BOM = '\uFEFF';
      downloadCSV(`${BOM} ${plainCsv}`, 'HomeDeliveryReport');
    });
  };

  const handleSubmit = () => {
    setLoading(true);
    setMessage(undefined);
    const fromFullDate = `${dateFrom} 00:00:00`;
    const untilFullDate = `${dateUntil} 23:59:59`;

    const from = moment(fromFullDate).utc().format();
    const until = moment(untilFullDate).utc().format();
    const promotionType = PROMOTION_TYPES.HOME_DELIVERY;
    const status = REQUESTED;

    client.query({
      query: ORDERS_QUERY,
      variables: {
        filter: {
          createdFrom: from,
          createdUntil: until,
          promotionType,
          status,
        },
      },
    })
      .then((res) => {
        const zero = 0;
        const { data } = res;
        const { orders } = data;
        const { results } = orders;

        if (results.length <= zero) {
          setMessage(t('orders.export.noResults'));
        } else {
          setMessage(t('orders.export.results', { total: results?.length }));
          ordersExporter(results);
        }

        setLoading(false);
      })
      .catch(() => {
        setMessage(t('orders.export.error'));
        setLoading(false);
      });
  };

  return (
    <SimpleForm style={{ width: '100%' }} onSubmit={() => null} toolbar={null}>
      <div style={{ width: '100%', margin: '0 0 20px 0' }}>
        <Typography variant="h1">{t('orders.export.title')}</Typography>
      </div>
      <div className={classes.mainRowGap}>
        <div className={classes.mainRowCol}>
          <DateInput
            source="dateFrom"
            type="date"
            label={t('orders.export.labelFrom')}
            value={dateFrom}
            onChange={e => setDateFrom(e.target.value)}
          />
        </div>
        <div className={classes.mainRowCol}>
          <DateInput
            source="dateUntil"
            type="date"
            label={t('orders.export.labelUntil')}
            value={dateUntil}
            onChange={e => setDateUntil(e.target.value)}
          />
        </div>
      </div>
      <div className={classes.mainRowGap}>
        <Button
          onClick={handleSubmit}
          color="primary"
          variant="contained"
          disabled={!dateFrom || !dateUntil || loading}
        >
          {loading ? t('orders.export.loading') : t('orders.export.request')}
        </Button>
      </div>
      {message !== undefined && typeof message === 'string' && (
        <div className={classes.mainRowGap} style={{ width: '100%' }}>
          <Typography variant="body1">{message}</Typography>
        </div>
      )}
    </SimpleForm>
  );
};

export default PromotionOrdersReport;
