import React, { useLayoutEffect, useState } from 'react';
import {
  Datagrid, TextField, Pagination, useTranslate, useNotify, Loading,
} from 'react-admin';
import { Box, Button } from '@material-ui/core';
import TemplateList from '../../../lib/components/TemplateList';
import { updateSyndicationClientList } from '../../../lib/utils/products';


const StepThree = (props) => {
  const {
    eans, action, client, setActiveStep, PROGRESS,
  } = props;
  const translate = useTranslate();
  const notify = useNotify();
  const [loading, setLoading] = useState(true);

  const globalFont = { fontFamily: 'Noto Sans, Arial, Helvetica Neue, Helvetica, sans-serif' };

  useLayoutEffect(() => {
    const processEans = async () => {
      setLoading(true);
      const data = await updateSyndicationClientList(client, action, eans);

      if (data === false) {
        notify(translate('products.syndication.stepThree.nothingToDo'), 'info');
      } else {
        notify(translate('products.syndication.stepThree.success'), 'success');
      }
    };
    processEans().then(() => { setLoading(false); })
      .catch(() => { setLoading(false); });
  }, []);
  return (
    <div>
      <h3 style={{ ...globalFont }}>{translate('products.syndication.stepThree.title')}</h3>
      {loading && (<Loading />)}
      {!loading && (
        <>
          <h3 style={{ ...globalFont }}>{translate('products.syndication.stepThree.subtitle')}</h3>
          <TemplateList basePath="products" resource="products" filter={{ eans }}>
            <Datagrid>
              <TextField source="ean" />
              <TextField source="name" />
              <TextField source="brand" />
            </Datagrid>
            <Pagination />
            <Box style={{
              marginTop: '20px', display: 'flex', flexFlow: 'row wrap', justifyContent: 'space-around',
            }}
            >
              <Button
                onClick={() => {
                  setActiveStep(PROGRESS.INSERT);
                }}
                variant="contained"
                color="primary"
              >
                {translate('products.syndication.steps.start')}
              </Button>
              <Button
                onClick={() => {
                  setActiveStep(PROGRESS.SELECT_ACTION);
                }}
                variant="contained"
                color="primary"
              >
                {translate('products.syndication.steps.prev')}
              </Button>
            </Box>
          </TemplateList>
        </>
      )}
    </div>
  );
};

export default StepThree;
