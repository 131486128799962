import React from 'react';
import {
  ArrayField, ChipField,
  Datagrid, DateField, DateInput, downloadCSV, List, SingleFieldList, TextField, TextInput,
} from 'react-admin';
import jsonExport from 'jsonexport/dist';
import { translate } from 'ra-core/lib';
import { parseDateFromQuery } from '../../lib/utils/date';

const unSubFilters = [
  <TextInput source="username" alwaysOn placeholder={translate('user.filter.username')} label="Username" />,
  <DateInput source="startDateFrom" label="Start Date" alwaysOn />,
  <DateInput source="startDateUntil" label="Until Date" alwaysOn />,
];

const exporter = (unsub) => {
  const userForExport = unsub.map((userData) => {
    const {
      userId, createdAt, createdByAdmin, reasons, customReason,
    } = userData;
    const YES = 'Yes';
    const NO = '-';

    return {
      userId,
      createdAt: parseDateFromQuery(createdAt),
      fewProducts: reasons && reasons.some(r => r.code === 'fewProducts') ? YES : NO,
      productsNotAvailableInStore: reasons && reasons.some(r => r.code === 'productsNotAvailableInStore')
        ? YES
        : NO,
      nonParticipatingStore: reasons && reasons.some(r => r.code === 'nonParticipatingStore')
        ? YES
        : NO,
      uninterestingProducts: reasons && reasons.some(r => r.code === 'uninterestingProducts')
        ? YES
        : NO,
      lackOfKnowledge: reasons && reasons.some(r => r.code === 'lackOfKnowledge')
        ? YES
        : NO,
      littleTime: reasons && reasons.some(r => r.code === 'littleTime')
        ? YES
        : NO,
      technicalProblems: reasons && reasons.some(r => r.code === 'technicalProblems')
        ? YES
        : NO,
      deletedByAdmin: createdByAdmin ? `Deleted by Admin User: ${createdByAdmin}` : NO,
      customReason: customReason || NO,
    };
  });
  jsonExport(userForExport, {
    headers: [
      'userId',
      'createdAt',
      'fewProducts',
      'productsNotAvailableInStore',
      'uninterestingProducts',
      'lackOfKnowledge',
      'littleTime',
      'technicalProblems',
      'deletedByAdmin',
      'customReason',
    ],
  }, (err, csv) => {
    downloadCSV(csv, 'unsubscription report');
  });
};
const UnsubList = props => (
  <List
    {...props}
    filters={unSubFilters}
    resource="unSubs"
    basePath="/unsub"
    exporter={exporter}
    bulkActionButtons={false}
  >
    <Datagrid>
      <TextField source="deletedUser.email" label="Username" />
      <TextField source="deletedUser.name" label="Name" />
      <TextField source="deletedUser.lastName" label="Lastname" />
      <DateField source="deletedUser.createdAt" label="Created At" />
      <DateField source="deletedUser.deletedAt" label="Deleted At" />
      <ArrayField source="reasons" label="Reasons">
        <SingleFieldList>
          <ChipField source="code" />
        </SingleFieldList>
      </ArrayField>
      <TextField source="customReason" label="Custom Reason" />
      <TextField source="createdByAdmin" label="Created By Admin" />
    </Datagrid>
  </List>
);

export default UnsubList;
