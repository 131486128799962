import React, { useEffect, useState } from 'react';
import { useTranslate } from 'react-admin';
import { Box, Button } from '@material-ui/core';
import { FILTER_SEPARATOR, isValidEan, MAX_EAN_SYNDICATION_LENGTH } from '../../../lib/utils/products';

const StepOne = (props) => {
  const {
    eans, setEans, setErrorStep, setActiveStep, PROGRESS,
  } = props;
  const translate = useTranslate();
  const [text, setText] = useState('');
  const [error, setError] = useState(false);
  const globalFont = { fontFamily: 'Noto Sans, Arial, Helvetica Neue, Helvetica, sans-serif' };
  const zero = 0;

  useEffect(() => {
    const initialErrorStep = -1;
    const eanList = text.split(FILTER_SEPARATOR);
    const isInLimit = eanList.length <= MAX_EAN_SYNDICATION_LENGTH;

    if (isInLimit) {
      setErrorStep(initialErrorStep);
      setError(false);
      const filterList = eanList
        .map(itemRaw => itemRaw.trim())
        .filter(item => isValidEan(item));

      setEans(filterList);
    } else {
      setErrorStep(PROGRESS.INSERT);
      setError(true);
    }
  }, [text]);

  return (
    <div>
      <h3 style={{ ...globalFont }}>{translate('products.syndication.stepOne.title')}</h3>
      {error && <p style={{ color: 'red' }}>{translate('products.syndication.stepOne.errorMaxEans')}</p>}
      <textarea onChange={e => setText(e.target.value)} style={{ width: '100%', minHeight: '150px' }} />
      <Box style={{ marginTop: '20px', textAlign: 'center' }}>
        <Button onClick={() => setActiveStep(PROGRESS.SELECT_ACTION)} disabled={error || eans.length === zero} variant="contained" color="primary">
          {translate('products.syndication.steps.continue')}
        </Button>
      </Box>
    </div>
  );
};

export default StepOne;
