const CRF_CARD = 19;
const CRF_PREFIX = '913572';
const PASS_PREFIX = '055';
const PASS_CARD = 16;
const isValidLuhn = (number) => {
  const digits = number.split('').reverse().map(digit => parseInt(digit, 10));

  const sum = digits.map((digit, index) => {
    if (index % 2 !== 0) {
      const doubled = digit * 2;
      return doubled > 9 ? doubled - 9 : doubled;
    }
    return digit;
  }).reduce((acc, curr) => acc + curr, 0);

  return sum % 10 === 0;
};

export {
  isValidLuhn, CRF_CARD, CRF_PREFIX, PASS_CARD, PASS_PREFIX,
};
