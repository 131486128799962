import React from 'react';
import { SaveButton, Toolbar, useTranslate } from 'react-admin';


const ProcessToolbar = (props) => {
  const translate = useTranslate();
  const { saving, checking } = props;

  return (
    <Toolbar {...props}>
      <SaveButton
        label={saving ? translate('products.uploads.buttonSaving') : translate('products.uploads.button')}
        disabled={checking}
      />
    </Toolbar>
  );
};

export default ProcessToolbar;
