import * as React from 'react';
import { ListContextProvider, useListController } from 'react-admin';


const TemplateList = ({ children, ...props }) => (
  <ListContextProvider value={useListController(props)}>
    {children}
  </ListContextProvider>
);

export default TemplateList;
