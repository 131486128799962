import _ from 'lodash';
import { encryptDate } from '../../resources/users/utils';
import { parseDateQuery } from './date';

const PHONE_PREFIX = '+507 ';
const NOT_ANSWERED = 'NOT_ANSWERED';
const YES = 'YES';
const NO = 'NO';
const genderOptions = translate => [
  { id: 'MALE', name: translate('user.edit.gender.man') },
  { id: 'FEMALE', name: translate('user.edit.gender.woman') },
];
const dietsOptions = translate => [
  { id: 'omnivorous', name: translate('user.edit.dietOptions.omnivorous') },
  { id: 'vegetarian', name: translate('user.edit.dietOptions.vegetarian') },
  { id: 'vegan', name: translate('user.edit.dietOptions.vegan') },
  { id: 'rawFood', name: translate('user.edit.dietOptions.rawFood') },
  { id: 'flexitarian', name: translate('user.edit.dietOptions.flexitarian') },
  { id: 'other', name: translate('user.edit.dietOptions.other') },
];

const binaryOptions = translate => [
  { id: YES, name: translate('user.edit.yes') },
  { id: NO, name: translate('user.edit.no') },
  { id: NOT_ANSWERED, name: translate('user.edit.not_answered') },
];
const binary2Options = translate => [
  { id: YES, name: translate('user.edit.yes') },
  { id: NO, name: translate('user.edit.no') },
];
const petsOptions = translate => [
  { id: 'DOG', name: translate('user.edit.Dog') },
  { id: 'CAT', name: translate('user.edit.Cat') },
  { id: 'BIRD', name: translate('user.edit.Bird') },
  { id: 'MOUSE', name: translate('user.edit.Mouse') },
  { id: 'FISH', name: translate('user.edit.Fish') },
  { id: 'OTHER', name: translate('user.edit.Other') },
];
const interestOptions = translate => [
  { id: 'sports', name: translate('user.edit.sports') },
  { id: 'reading', name: translate('user.edit.reading') },
  { id: 'cultural', name: translate('user.edit.cultural') },
];
const allergyOptions = translate => [
  { id: 'gluten', name: translate('user.edit.allergiesOptions.gluten') },
  { id: 'milk', name: translate('user.edit.allergiesOptions.milk') },
  { id: 'corn', name: translate('user.edit.allergiesOptions.corn') },
  { id: 'fish', name: translate('user.edit.allergiesOptions.fish') },
  { id: 'almonds', name: translate('user.edit.allergiesOptions.almonds') },
  { id: 'peanuts', name: translate('user.edit.allergiesOptions.peanuts') },
  { id: 'molluscs', name: translate('user.edit.allergiesOptions.molluscs') },
  { id: 'eggs', name: translate('user.edit.allergiesOptions.eggs') },
  { id: 'soybeans', name: translate('user.edit.allergiesOptions.soybeans') },
  { id: 'other', name: translate('user.edit.allergiesOptions.other') },
];

const lifestyleOptions = translate => [
  { id: 'health', name: translate('user.edit.health') },
  { id: 'selfEducation', name: translate('user.edit.selfEducation') },
  { id: 'environment', name: translate('user.edit.environment') },
  { id: 'aesthetics', name: translate('user.edit.aesthetics') },
  { id: 'healthWithoutTime', name: translate('user.edit.healthWithoutTime') },
  { id: 'socialMedia', name: translate('user.edit.socialMedia') },
  { id: 'qualityTime', name: translate('user.edit.qualityTime') },
  { id: 'careerAndFamily', name: translate('user.edit.careerAndFamily') },
  { id: 'closeFriends', name: translate('user.edit.closeFriends') },
  { id: 'gardening', name: translate('user.edit.gardening') },
];
const dietOptions = translate => [
  { id: 'omnivorous', name: translate('user.edit.omnivorous') },
  { id: 'flexitarian', name: translate('user.edit.flexitarian') },
  { id: 'vegetarian', name: translate('user.edit.vegetarian') },
  { id: 'vegan', name: translate('user.edit.vegan') },
  { id: 'other', name: translate('user.edit.other') },
];
const homeComponentOptions = [
  { id: '1', name: '1' },
  { id: '2', name: '2' },
  { id: '3', name: '3' },
  { id: '4', name: '4' },
  { id: '5', name: '5' },
  { id: '6', name: '6' },
  { id: '7', name: '7' },
  { id: '8', name: '8' },
  { id: '9', name: '9' },
  { id: '10', name: '10' },
  { id: '11', name: '11' },
];
const childComponentOptions = [
  { id: '0', name: '0' },
  { id: '1', name: '1' },
  { id: '2', name: '2' },
  { id: '3', name: '3' },
  { id: '4', name: '4' },
  { id: '5', name: '5' },
  { id: 'NOT_ANSWERED', name: 'Unanswered' },
];
const parseProfileForm = (formInput) => {
  const policies = [];
  const notAnswered = [];

  const policiesOptions = [
    {
      code: 'platformCommunications',
      name: 'Platform Communications',
    },
    {
      code: 'retailerCommunications',
      name: 'Retailer Communications',
    },
    {
      code: 'dataLinkWithSAZ',
      name: 'Link profile with ShopAdvizor',
    },
    {
      code: 'thirdPartyCommunications',
      name: 'Third Party Communications',
    },
  ];
  const {
    userId,
    gender,
    birthDate,
    petsValues,
    children,
    hasAlcohol,
    numberOfChildren,
    hasPet,
  } = formInput;
  const allPolicies = ['platformCommunications', 'retailerCommunications', 'dataLinkWithSAZ', 'thirdPartyCommunications'];

  allPolicies.forEach((policy) => {
    if (formInput[policy] === true) {
      policies.push(policiesOptions.find(item => item.code === policy));
    }
  });
  let petsForGraphQl = petsValues?.map(item => ({ type: item })) ?? [];
  const childrenForGraphQl = children?.map(item => ({ birthDate: item?.birthDate })) ?? [];
  const alcoholForGraphQl = hasAlcohol === YES;
  if (hasAlcohol === NOT_ANSWERED) {
    notAnswered.push('alcohol');
  }
  if (numberOfChildren === NOT_ANSWERED) {
    notAnswered.push('children');
  }
  if (hasPet === NOT_ANSWERED) {
    notAnswered.push('pets');
  }
  if (hasPet === NO) {
    petsForGraphQl = [];
  }
  return {
    policies,
    userId,
    gender,
    birthDate,
    preference: (hasAlcohol === NOT_ANSWERED) ? {} : { alcohol: alcoholForGraphQl },
    pets: hasPet === NOT_ANSWERED ? null : petsForGraphQl,
    children: numberOfChildren === NOT_ANSWERED ? null : childrenForGraphQl,
    notAnswered,
  };
};
const parseCreateProfileForm = (formInput) => {
  const profileEdit = parseProfileForm(formInput);
  return { ...profileEdit };
};

const buildUpdateChilds = (values, recordContext) => {
  const { children, numberOfChildren } = values;
  const zero = 0;
  const one = 1;

  if ((!children || children?.length === zero) && !numberOfChildren) {
    return null;
  }

  if (numberOfChildren) {
    const beforeChilds = recordContext?.children?.length || zero;
    const parsedMembers = parseInt(numberOfChildren, 10);

    if (beforeChilds > parsedMembers) {
      const diff = beforeChilds - parsedMembers;
      const lastIndex = parsedMembers + diff;
      const deletedIndex = [];

      for (let i = parsedMembers; i < lastIndex; i += one) {
        deletedIndex.push(i);
      }

      return { delete: deletedIndex };
    }

    const childCreate = [];

    if (children?.[0]?.birthDate) {
      const childEdit1 = {
        birthDate: parseDateQuery(children?.[0]?.birthDate),
      };

      childCreate.push(childEdit1);
    }

    if (children?.[1]?.birthDate) {
      const childEdit2 = {
        birthDate: parseDateQuery(children?.[1]?.birthDate),
      };

      childCreate.push(childEdit2);
    }

    if (children?.[2]?.birthDate) {
      const childEdit3 = {
        birthDate: parseDateQuery(children?.[2]?.birthDate),
      };

      childCreate.push(childEdit3);
    }

    if (children?.[3]?.birthDate) {
      const childEdit4 = {
        birthDate: parseDateQuery(children?.[3]?.birthDate),
      };

      childCreate.push(childEdit4);
    }

    if (children?.[4]?.birthDate) {
      const childEdit5 = {
        birthDate: parseDateQuery(children?.[4]?.birthDate),
      };

      childCreate.push(childEdit5);
    }

    if (childCreate.length > zero) {
      return { create: childCreate.filter(child => child.birthDate !== null) };
    }
    return null;
  }

  const childEdit = [];

  if (children?.[0]?.birthDate) {
    const childEdit1 = {
      index: 0,
      input: {
        birthDate: parseDateQuery(children?.[0]?.birthDate),
      },
    };

    childEdit.push(childEdit1);
  }

  if (children?.[1]?.birthDate) {
    const childEdit2 = {
      index: 1,
      input: {
        birthDate: parseDateQuery(children?.[1]?.birthDate),
      },
    };

    childEdit.push(childEdit2);
  }
  if (children?.[2]?.birthDate) {
    const childEdit3 = {
      index: 2,
      input: {
        birthDate: parseDateQuery(children?.[2]?.birthDate),
      },
    };

    childEdit.push(childEdit3);
  }
  if (children?.[3]?.birthDate) {
    const childEdit4 = {
      index: 3,
      input: {
        birthDate: parseDateQuery(children?.[3]?.birthDate),
      },
    };

    childEdit.push(childEdit4);
  }
  if (children?.[4]?.birthDate) {
    const childEdit5 = {
      index: 4,
      input: {
        birthDate: parseDateQuery(children?.[4]?.birthDate),
      },
    };

    childEdit.push(childEdit5);
  }
  const childSend = childEdit.filter(child => child.input.birthDate !== null);
  if (childSend.length > zero) {
    return { update: childSend };
  }

  return null;
};

const buildUpdateProfileParams = (input) => {
  const {
    gender,
    numberOfChildren,
    hasPet,
    hasAllergy,
    petsValues,
    allergyValues,
    hasAlcohol,
    children,
    recordContext,
    birthDate,
    diet,
  } = input;
  const output = {};

  const childsParsed = buildUpdateChilds({ children, numberOfChildren }, recordContext);
  if (gender) {
    output.gender = input.gender;
  }

  if (birthDate) {
    output.birthDate = parseDateQuery(input.birthDate);
  }

  const allergiesForGraphQl = allergyValues?.map(item => ({ code: item })) ?? [];

  if (hasAllergy === NOT_ANSWERED) {
    output.allergies = null;
  }
  if (hasAllergy === NO) {
    output.allergies = [];
  }

  if (allergyValues || hasAllergy === YES) {
    output.allergies = allergiesForGraphQl;
  }
  const petsForGraphQl = petsValues?.map(item => ({ type: item })) ?? [];

  if (hasPet === NOT_ANSWERED) {
    output.pets = null;
  }
  if (hasPet === NO) {
    output.pets = [];
  }

  if (petsValues || hasPet === YES) {
    output.pets = petsForGraphQl;
  }
  const alcoholForGraphQl = hasAlcohol === YES;
  if (hasAlcohol) {
    output.preference = (hasAlcohol === NOT_ANSWERED)
      ? { alcohol: null }
      : { alcohol: alcoholForGraphQl };
  }
  if (childsParsed) {
    output.updateChildrenInput = childsParsed;
  }
  if (numberOfChildren === NOT_ANSWERED) {
    output.children = null;
  }
  if (diet) {
    output.diet = { code: input.diet };
  }
  return output;
};
const buildUpdateUserParams = (input) => {
  const {
    username,
    name,
    lastName,
    active,
    isAdmin,
    communicationsPolicy,
    conditions,
    shuffleConnectionPolicy,
  } = input;
  const output = {
    ...(username ? { username } : {}),
    ...(name ? { name } : {}),
    ...(lastName ? { lastName } : {}),
    ...(active !== undefined ? { active } : {}),
    ...(isAdmin !== undefined ? { role: isAdmin ? 'ADMIN' : 'USER' } : {}),
    ...(communicationsPolicy !== undefined ? { communicationsPolicy } : {}),
    ...(shuffleConnectionPolicy !== undefined ? { shuffleConnectionPolicy } : {}),
    ...(conditions !== undefined ? { conditions } : {}),
  };
  return output;
};

const parseEditProfileDiffForm = (fields, variables, recordContext) => {
  const output = {};
  Object.entries(fields).forEach(([index]) => {
    output[index] = variables[index];
  });
  const inputParams = buildUpdateProfileParams({ recordContext, ...output });
  return {
    id: recordContext.id,
    input: { ...inputParams },
  };
};
const parseCreateProfileDiffForm = (fields, variables, recordContext) => {
  const output = {};

  Object.entries(fields).forEach(([index]) => {
    output[index] = variables[index];
  });
  const inputParams = buildUpdateProfileParams({ recordContext, ...output });

  return {
    input: { userId: recordContext.userId, ...inputParams },
  };
};
const parseEditUserDiffForm = (fields, variables, recordContext) => {
  const output = {};
  Object.entries(fields).forEach(([index]) => {
    output[index] = variables[index];
  });
  const inputParams = buildUpdateUserParams({ recordContext, ...output });

  return {
    id: recordContext.id,
    ...inputParams,
  };
};
const parseChildrenResponse = (children) => {
  if (children) {
    return children.map(item => ({
      ...item,
      birthDate: encryptDate(item?.birthDate),
    }));
  }

  return null;
};

const buildResponse = (response) => {
  const output = response;
  if (_.has(response, 'policies')) {
    const policies = {
      thirdPartyCommunications: _.find(response?.policies, { code: 'thirdPartyCommunications' }) !== undefined,
      retailerCommunications: _.find(response?.policies, { code: 'retailerCommunications' }) !== undefined,
      platformCommunications: _.find(response?.policies, { code: 'platformCommunications' }) !== undefined,
      dataLinkWithSAZ: _.find(response?.policies, { code: 'dataLinkWithSAZ' }) !== undefined,
    };
    _.assignIn(output, policies);
  }
  if (_.has(response, 'educationLevel.code')) {
    _.assignIn(output, { educationCode: response?.educationLevel.code });
  }
  const zero = 0;
  const alcoholValue = response?.preference?.alcohol === true ? 'YES' : 'NO';
  const petValue = response?.pets?.length > zero ? 'YES' : 'NO';
  const allergyValue = response?.allergies?.length > zero ? 'YES' : 'NO';
  const hasAlcohol = response?.preference?.alcohol === null ? 'NOT_ANSWERED' : alcoholValue;
  const hasPet = (response?.pets === null) ? 'NOT_ANSWERED' : petValue;
  const hasAllergy = (response?.allergies === null) ? 'NOT_ANSWERED' : allergyValue;
  const childrenLength = response?.children ? response?.children?.length.toString() : '0';
  const numberOfChildren = response?.children === null ? 'NOT_ANSWERED' : childrenLength;
  return {
    ...output,
    diet: response?.diet?.code ? response?.diet?.code : null,
    birthDate: response?.birthDate ? encryptDate(response?.birthDate) : response?.birthDate,
    children: parseChildrenResponse(response?.children) || [],
    petsValues: response?.pets?.map(item => item.type),
    allergyValues: response?.allergies?.map(item => item.code),
    platformCommunications: _.find(response?.policies, { code: 'platformCommunications' }) !== undefined,
    numberOfChildren,
    hasAlcohol,
    hasPet,
    hasAllergy,
  };
};

export {
  dietOptions,
  lifestyleOptions,
  interestOptions,
  petsOptions,
  genderOptions,
  homeComponentOptions,
  binaryOptions,
  childComponentOptions,
  parseProfileForm,
  parseCreateProfileForm,
  parseEditProfileDiffForm,
  PHONE_PREFIX,
  buildResponse,
  parseEditUserDiffForm,
  buildUpdateProfileParams,
  parseCreateProfileDiffForm,
  dietsOptions,
  allergyOptions,
  binary2Options,
};
