import ManufacturerIcon from '@material-ui/icons/Domain';
import ManufacturerList from './ManufacturerList';
import ManufacturerCreate from './Create';
import ManufacturerEdit from './Edit';

export default {
  list: ManufacturerList,
  create: ManufacturerCreate,
  edit: ManufacturerEdit,
  icon: ManufacturerIcon,
};
